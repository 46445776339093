"use client";

import { ProductFilteredListContainer } from "containers/ProductFilteredList/ProductFilteredList";
import { UseProductFilteredListCreateContextParams } from "containers/ProductFilteredList/hooks";
import { ProductUrlGenerator } from "hooks/useProductUrlGenerator";

import { useConsumerTripProductClickEventRegistration } from "../../hooks/useConsumerTripProductClickEventRegistration";
import { CurationProps } from "./CurationV2";

const TREE_IDS: UseProductFilteredListCreateContextParams["treeIds"] = ["CATEGORY", "ATTRIBUTE", "PROVIDER", "PRICE"];

type CurationProductsFilteredListV2Props = {
    curation: CurationProps["curation"];
    urlGenerator: ProductUrlGenerator;
    showTitle: boolean;
};

export function CurationProductsFilteredListV2({
    curation,
    showTitle,
    urlGenerator,
}: CurationProductsFilteredListV2Props) {
    const registerProductClicked = useConsumerTripProductClickEventRegistration();
    const filter = { curationSlugId: curation.id };

    return (
        <ProductFilteredListContainer
            filter={filter}
            treeIds={TREE_IDS}
            searchTerm={curation.name}
            showTitle={showTitle}
            showFiltersTree={true}
            onProductClick={(item, position) => {
                void registerProductClicked({
                    itemId: item.id,
                    itemPosition: position,
                    productOrigin: "CURATION",
                });
            }}
            urlGenerator={urlGenerator}
            hideUnlessProducts
        />
    );
}
